<template>
  <div>
    <div class="row m0 breadcrumbs-container py15" :class="{ 'is-visible': navVisible }">
      <top-bar class="row w-100 container-fluid cl-white bg-cl-alternative visible-xs-and-tablet m0" />
      <div class="breadcrumbs-inner col-xs-12">
        <div v-if="paths.length > 0" class="col-xs-1 btn-back visible-xs-and-tablet" @click="goBack()">
          <i class="arrow top" />
        </div>
        <div v-if="paths.length === 0" class="w-100 center-xs flex visible-xs-and-tablet">
          <logo width="100%" height="32px" />
        </div>
        <div v-show="!isHomePage" class="breadcrumbs h5 weight-400" vocab="http://schema.org/" typeof="BreadcrumbList">
          <span property="itemListElement" typeof="ListItem">
            <router-link :to="'/'" :href="hostName" property="item" typeof="WebPage">
              <span class="hidden" property="name">{{ hostName }}</span>
              <span>strona główna</span>
            </router-link> /
            <meta property="position" content="0">
          </span>
          <span v-for="(link, index) in paths" :key="link.route_link" property="itemListElement" typeof="ListItem" v-if="link.route_link !== '/default-category' && link.route_link !== '/sklep' && link.route_link !== '/' && link.route_link !== '/all-products'">
            <router-link :to="link.route_link" property="item" typeof="WebPage">
              <span property="name">{{ $t(link.name) | htmlDecode }}</span>
            </router-link> /
            <meta property="position" :content="index">
          </span>
          <span>
            {{ current | htmlDecode }}
          </span>
        </div>
      </div>
    </div>
    <div class="visible-xs-and-tablet">
      <div class="row m0 breadcrumbs-container" :class="{ 'is-visible': !navVisible }">
        <div @click.prevent="toggleSearchpanel" class="search-input-container">
          <div class="search-input-group">
            <i class="icon-search cl-white search-icon" />
            <input
              ref="search"
              id="search"
              v-model="searchPlaceholder"
              class="search-panel-input"
              placeholder=""
              type="text"
            >
          </div>
          <span class="cl-alternative flex center-xs middle-xs pointer lh30 m10">
            Szukaj
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Breadcrumbs } from '@vue-storefront/core/modules/breadcrumbs/components/Breadcrumbs.ts'
import config from 'config'
import Logo from 'theme/components/core/Logo';
import TopBar from 'theme/components/core/blocks/Header/TopBar'
import CurrentPage from 'theme/mixins/currentPage';
import { mapState } from 'vuex'

export default {
  mixins: [Breadcrumbs, CurrentPage],
  components: {
    TopBar,
    Logo
  },
  data () {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 54,
      searchPlaceholder: ''
    };
  },
  beforeMount () {
    this.$bus.$on('has-scrolled', this.hasScrolled)
  },
  beforeDestroy () {
    this.$bus.$off('has-scrolled', this.hasScrolled)
  },
  computed: {
    hostName () {
      return config.server.hostName
    },
    ...mapState({
      isOpen: state => state.ui.searchpanel
    })
  },
  methods: {
    toggleSearchpanel () {
      this.$store.commit('ui/setSearchpanel', !this.isOpen)
    },
    // toggleQueryFilter () {
    //   this.closeSearchpanel()
    //   this.$router.push({ path: '/kategorie-produktow', query: { query: this.search } })
    // },
    // clearSearchPanel () {
    //   this.search = ''
    //   this.makeSearch();
    // },
    hasScrolled () {
      this.scrollTop = window.scrollY;
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false;
      } else {
        this.navVisible = true;
      }
      this.lastScrollTop = this.scrollTop;
    },
    goBack () {
      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$border-color: color(matterhorn);

.search-input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  border-bottom: 1px solid #bdbdbd;
  .search-input-group {
    position: relative;
    padding-right: 40px;
    width: 100%;
    background: #fff;
    top: 0;
    margin-right: 10px;
  }
  .search-panel-input {
    width: 100%;
    margin: 5px;
    height: auto;
    padding-left: 40px;
    border: none;
    outline: 0;
    font-size: 16px;
    font-family: "protipo",sans-serif;
    -webkit-appearance: none;
    background-color: #f5f5f5;
    border-radius: 15px;
  }
  .clear-icon {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 15px;
    top: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 10px;
    background-color: #ababab;
    color: #f5f5f5;
    padding: 5px;
    border-radius: 50%;
  }
  .search-icon {
    position: absolute;
    left: 20px;
    top: 35%;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    filter: contrast(0.0);
  }
}
.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  right: 20px;
  bottom: 22px;
}
.top {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.breadcrumbs-inner {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    padding: 10px 5px 10px 15px;
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
  .breadcrumbs-container {
    top: -82px;
    z-index: 2;
    transition: top 0.2s ease-in-out;
    position: fixed;
    padding: 0;
    // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    left: 0;
    &.is-visible {
      top: 0;
    }
  }
}
.btn-back {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    padding: 8px 0 8px 5px;
    border: 1px solid $border-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
 .breadcrumbs {
   font-size:12px;
   text-transform: uppercase;
 }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px)  {
    .breadcrumbs {
      padding-left: 8px;
      font-size:10px;
      text-transform: uppercase;
      color: #707070;
    }
 }
</style>
