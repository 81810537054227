<template>
  <div class="baner-carousel align-center weight-400 h6 uppercase">
    <no-ssr>
      <hooper
        :auto-play="true"
        :play-speed="2000"
        :infinite-scroll="true"
        :mouse-drag="false"
      >
        <slide class="center-content" v-for="(banner, index) in validBanners" :key="index">
          <template v-if="banner.link">
            <router-link :to="localizedRoute(banner.link)">
              <span class="top-bar-text link">
                {{ banner.text }}
              </span>
            </router-link>
          </template>
          <template v-else>
            <span class="top-bar-text">
              {{ banner.text }}
            </span>
          </template>
        </slide>
        <hooper-navigation slot="hooper-addons" class="hidden-xs" />
      </hooper>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'TopBar',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    'no-ssr': NoSSR
  },
  props: {
  },
  data () {
    return {
      validBanners: {}
    }
  },
  mounted () {
    if (this.data) {
      this.blocksContent()
    }
  },
  watch: {
    data: function () {
      this.blocksContent()
    }
  },
  computed: {
    data () {
      return this.$store.getters[`cmsBlock/cmsBlocks`]
    }
  },
  methods: {
    blocksContent () {
      if (this.data && this.data.length) {
        let banners = this.data
        let bannerInfo = {}
        for (let banner of banners) {
          if (banner.identifier.includes('info')) {
            bannerInfo = banner.content.replace(/<\/?p>/g, '')
            bannerInfo = JSON.parse(bannerInfo)
            this.validBanners = bannerInfo
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.baner-carousel {
  .hooper {
    height: auto;
    .hooper-track {
      display: flex;
      align-items: center;
    }
    .hooper-next {
      right: -35px;
      filter: brightness(0) invert(1);
    }
    .hooper-prev {
      left: -35px;
      filter: brightness(0) invert(1);
    }
  }
}
</style>

<style lang="scss" scoped>
.link {
  &:hover {
    text-decoration: underline;
  }
}
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.baner-carousel {
  padding: 7.5px 0;
  width: 440px;
  margin: 0 auto;
  height: 15px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    height: 30px;
    width: 100%;
  }
  @media (max-width: 767px) {
    height: 30px;
    width: 100%;
  }
}
.top-bar-text {
  @media (max-width: 767px) {
    font-size: 10px;
  }
}
</style>
